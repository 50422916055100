<template>
  <v-container>
    <v-row>
      <v-col v-for="dispatch in dispatchesData" :key="dispatch.id" cols="4">
        <v-card>
          <v-row>
            <v-col cols="9">
              <v-card-title>
                <span v-html="dispatch.driver.name" />
              </v-card-title>
            </v-col>
          </v-row>
          <v-card-subtitle class="mb-2">
            <v-row>
              Start Date: <span v-html="formatDate(dispatch.startDate)" />
            </v-row>
            <v-row>
              Pick Up Site:
              <span v-html="formatSiteAddress(dispatch.haulRequest.site)" />
            </v-row>
            <v-row>
              Drop Off Site:
              <span
                v-html="formatSiteAddress(dispatch.importApproval.site)"
              />
            </v-row>
            <v-row>
              <v-col>
                <v-chip small :color="chipColor(dispatch.status.name)">{{
                  dispatch.status.name
                }}</v-chip>
              </v-col>
              <v-col>
                <v-btn
                  elevation="0"
                  x-small
                  class="mr-1"
                  color="success"
                  outlined
                  @click="sendSMS(item)"
                  >SMS to Driver</v-btn
                >
                <v-btn
                  x-small
                  elevation="0"
                  color="info"
                  @click="editDispatch(item)"
                  >Edit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { dateFromUTC } from '@/helpers/FormatHelper.js'
import { chipColorClass } from '@/helpers/StylingHelper.js'
export default {
  props: {
    dispatchesData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    chipColor (name) {
      return chipColorClass(name)
    },
    formatSiteAddress (site) {
      return `${site.name} (${site.siteCode}), ${site.address.addressLine1}, ${site.address.city.name}`
    },
    formatDate (date) {
      return dateFromUTC(date)
    }
  }
}
</script>
