<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-dialog
        v-model="formOpen"
        persistent
        style="width: 80%"
        width="80%"
        min-width="600px"
      >
        <v-card style="width: 100%">
          <v-card-title>New Dispatch</v-card-title>
          <v-card-text>
            <v-container style="width: 100%">
              <v-form v-model="valid" ref="form">
                <span class="text-h6 font-weight-bold">Haul</span>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      no-data-text="No Dispatchable Haul Requests"
                      v-model="submitData.haulRequestId"
                      :items="presentedHaulRequests"
                      :item-text="
                        (haulRequest) =>
                          `${haulRequest.code} (${haulRequest.site.name} - ${haulRequest.site.address.addressLine1}, ${haulRequest.site.address.city.name}, ${haulRequest.site.address.city.province.shortName} ${haulRequest.site.address.postalCode}) - ${haulRequest.siteContractorCompany.legalName}`
                      "
                      item-value="id"
                      :rules="[rules.required]"
                    >
                      <template #label>
                        Export Haul Request (Site)<red-asterisk />
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :disabled="!submitData.haulRequestId"
                      no-data-text="No Dispatchable Import Approvals (Sites)"
                      v-model="submitData.importApprovalId"
                      :items="applicableImportApprovals"
                      :item-text="
                        (importApproval) =>
                          `${importApproval.code} (${importApproval.site.name} - ${importApproval.site.address.addressLine1}, ${importApproval.site.address.city.name}, ${importApproval.site.address.city.province.shortName} ${importApproval.site.address.postalCode}) - ${importApproval.site.company.legalName}`
                      "
                      item-value="id"
                      :rules="[rules.required]"
                    >
                      <template #label>
                        Import Approval (Site)<red-asterisk />
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <span class="text-h6 font-weight-bold">Driver</span>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      no-data-text="Drivers Not Found"
                      v-model="submitData.driverId"
                      :items="sortedDrivers"
                      :item-text="
                        (driver) => `${driver.name} (${driver.driverCode})`
                      "
                      item-value="id"
                      :rules="[rules.required]"
                    >
                      <template #label> Driver<red-asterisk /> </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      no-data-text="Trucks Not Found"
                      v-model="submitData.vehicleId"
                      :items="sortedTrucks"
                      :item-text="formatTruckItemText"
                      item-value="id"
                      :rules="[rules.required]"
                    >
                      <template #label> Truck<red-asterisk /> </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <span class="text-h6 font-weight-bold">Shift</span>
                <v-row>
                  <v-col cols="3">
                    <v-menu
                      v-model="startDayMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="startDatePickerFormatter"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.required]"
                        >
                          <template #label>
                            Start Date<red-asterisk />
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDay"
                        :min="minStartDate"
                        @input="
                          () => {
                            updateMinEndDate();
                            menu = false;
                          }
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="menu"
                      v-model="startTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="startTimeFormatter"
                          label="Start Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="startTime"
                        full-width
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="endDayMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="!startDay"
                          :value="endDatePickerFormatter"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.required]"
                        >
                          <template #label> End Date<red-asterisk /> </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDay"
                        :min="minEndDate"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="menu"
                      v-model="endTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="!startTime"
                          :value="endTimeFormatter"
                          label="End Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="endTime"
                        full-width
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="text-right">
                  <v-spacer></v-spacer>
                  <v-btn text class="mr-2" @click="closeForm">Close</v-btn>
                  <v-btn variant="outlined" elevation="0" @click="submitDispatch">Submit</v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { combineDateTime } from '@/helpers/DateHelper.js'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import moment from 'moment'
export default {
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formOpen: false,
    valid: false,
    startDayMenu: false,
    startTimeMenu: false,
    endDayMenu: false,
    endTimeMenu: false,
    startDay: '',
    startTime: '',
    endDay: '',
    endTime: '',
    drivers: [],
    trucks: [],
    haulRequests: [],
    companies: [],
    minStartDate: moment(new Date().toLocaleDateString()).format('YYYY-MM-DD'),
    minEndDate: '',
    defaultSubmitData: {
      driverId: null,
      vehicleId: null,
      haulRequestId: null,
      importApprovalId: null,
      startDate: '',
      endDate: ''
    },
    submitData: {},
    rules: {
      required: (value) => !!value || 'Required.'
    }
  }),
  computed: {
    applicableImportApprovals () {
      if (this.submitData.haulRequestId) {
        const acceptedApprovalStatus = 2
        const haulRequestId = this.submitData.haulRequestId
        const haulRequest = this.haulRequests.find(
          (haulRequest) => haulRequest.id === haulRequestId
        )
        return haulRequest.importApprovals
          .filter(
            (importApproval) =>
              importApproval.haulRequestApprovalStatusId ===
              acceptedApprovalStatus
          )
          .sort((a, b) => new Date(a.issuedOn) - new Date(b.issuedOn))
      }
      return []
    },
    startDatePickerFormatter () {
      return this.startDay ? moment(this.startDay).format('YYYY-MMM-DD') : ''
    },
    endDatePickerFormatter () {
      return this.endDay ? moment(this.endDay).format('YYYY-MMM-DD') : ''
    },
    startTimeFormatter () {
      const placeholder = '2000-01-01T'
      return this.startTime
        ? moment(placeholder + this.startTime).format('hh:mm a')
        : ''
    },
    endTimeFormatter () {
      const placeholder = '2000-01-01T'
      return this.endTime
        ? moment(placeholder + this.endTime).format('hh:mm a')
        : ''
    },
    sortedDrivers () {
      // return this.drivers ? this.drivers.sort((a, b) => a.name.localeCompare(b.name)) : []
      if (this.drivers) {
        const sortedDrivers = this.drivers
        sortedDrivers.sort((a, b) => a.name.localeCompare(b.name))
        return sortedDrivers
      } else {
        return []
      }
    },
    sortedTrucks () {
      // return this.drivers ? this.drivers.sort((a, b) => a.name.localeCompare(b.name)) : []
      if (this.trucks) {
        const sortedTrucks = this.trucks
        sortedTrucks.sort((a, b) => a.model.make.localeCompare(b.model.make))
        return sortedTrucks
      } else {
        return []
      }
    },
    presentedHaulRequests () {
      if (this.haulRequests) {
        const haulRequests = this.haulRequests.map((haulRequest) => {
          const siteContractorCompany = this.companies.find(
            (c) =>
              c.id === haulRequest.exportingContractorCompanyProfileId ? haulRequest.exportingContractorCompanyProfileId : haulRequest.importingContractorCompanyProfileId
          )
          const haulingCompany = this.companies.find(
            (c) => c.id === haulRequest.haulingCompanyProfile.companyId
          )
          return {
            ...haulRequest,
            siteContractorCompany,
            haulingCompany
          }
        })

        return haulRequests.sort(
          (a, b) => new Date(a.createdOn) - new Date(b.createdOn)
        )
      }
      return []
    }
  },
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.drivers.length <= 0) {
        await this.getDrivers()
      }
      if (this.trucks.length <= 0) {
        await this.getTrucks()
      }
      if (this.haulRequests.length <= 0) {
        await this.getHaulRequests()
      }
      this.resetForm()
    },
    async getDrivers () {
      const params = [{ name: 'status', value: 1 }]
      const res = await this.$api.drivers.get(null, params)
      this.drivers = res
    },
    async getTrucks () {
      const params = [{ name: 'status', value: 1 }]
      const res = await this.$api.trucks.get(null, params)
      this.trucks = res
    },
    async getHaulRequests () {
      const res = await this.$api.haulingRequest.read(
        createPaginationObject(null, null, [{ name: 'CanDispatch' }])
      )
      this.companies = res.companies
      this.haulRequests = res.haulingRequests
    },
    async submitDispatch () {
      if (!this.$refs.form.validate()) return
      this.setDateTime()
      const res = await this.$api.dispatches.post(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Dispatch successfully created')
        this.$emit('submit-dispatch-form')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Dispatch')
      }
    },
    closeForm () {
      this.$refs.form.resetValidation()
      this.resetForm()
      this.$emit('close-form')
    },
    resetForm () {
      this.startDay = ''
      this.startTime = ''
      this.endDay = ''
      this.endTime = ''
      this.submitData = JSON.parse(JSON.stringify(this.defaultSubmitData))
    },
    setDateTime () {
      if (!this.startTime) {
        this.startTime = '00:00:00.000'
      }
      if (!this.endTime) {
        this.endTime = '23:59:59.999'
      }
      this.submitData.startDate = combineDateTime(
        this.startDay,
        this.startTime
      )
      this.submitData.endDate = combineDateTime(this.endDay, this.endTime)
    },
    formatTruckItemText (truck) {
      if (truck.truckNumber) {
        return `${truck.model.make} ${truck.model.name} (${truck.truckCode}) - Truck #${truck.truckNumber} (${truck.plateNo})`
      } else {
        return `${truck.model.make} ${truck.model.name} (${truck.truckCode}) - (${truck.plateNo})`
      }
    },
    updateMinEndDate () {
      this.minEndDate = this.startDay
    }
  }
}
</script>

<style>
.v-dialog > .v-overlay__content {
  width: 80%;
}
</style>
