<template>
  <v-container class="unreactive-margins">
    <v-data-table
      :headers="headers"
      :items="dispatchesData"
      sort-by="startDate"
      class="inverse-header-color"
    >
      <template #[`item.startDate`]="{ item }">
        {{ formatDate(item.startDate) }}
      </template>
      <template #[`item.endDate`]="{ item }">
        {{ formatDate(item.endDate) }}
      </template>
      <template #[`item.driver`]="{ item }">
        <strong>{{ item.driver.name }}</strong>
      </template>
      <template #[`item.cellPhone`]="{ item }">
        {{ formatPhone(item.driver.cellPhone) }}
      </template>
      <template #[`item.truck`]="{ item }">
        {{ item.vehicle.model.make + " " + item.vehicle.model.name }}
      </template>
      <template #[`item.plateNo`]="{ item }">
        {{ item.vehicle.plateNo }}
      </template>
      <template #[`item.truckNumber`]="{ item }">
        <strong>{{ item.vehicle.truckNumber }}</strong>
      </template>
      <template #[`item.truckCode`]="{ item }">
        {{ item.vehicle.truckCode }}
      </template>
      <template #[`item.haulRequestCode`]="{ item }">
        {{ item.haulRequest.code }}
      </template>
      <template #[`item.pickUpSite`]="{ item }">
        <strong>{{ formatSiteAddress(item.haulRequest.site) }}</strong>
      </template>
      <template #[`item.dropOffSite`]="{ item }">
        {{ formatSiteAddress(item.importApproval.site) }}
      </template>
      <template #[`item.createdBy`]="{ item }">
        {{ item.createdBy.firstName + " " + item.createdBy.lastName }}
      </template>
      <template #[`item.loads`]="{ item }">
        <!-- {{ item.loads ? item.loads.toLocaleString() : "0" }} -->
        {{ calculateApproximateLoads(item) }}
      </template>
      <template #[`item.status`]="{ item }">
        <div class="d-flex justify-center">
          <v-chip small :color="chipColor(item.status.name)">{{
            item.status.name
          }}</v-chip>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-btn
            elevation="0"
            x-small
            class="mr-1"
            color="success"
            outlined
            @click="sendSMS(item)"
            >SMS to Driver</v-btn
          >
          <v-btn
            v-if="isHauler"
            x-small
            elevation="0"
            color="info"
            @click="editDispatch(item)"
            >Edit</v-btn
          >
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex' // import { mapGetters, mapState, mapMutations } from 'vuex';
import { chipColorClass } from '@/helpers/StylingHelper.js'
import { formatPhoneString } from '@/helpers/FormatHelper.js'
import moment from 'moment'

export default {
  props: {
    dispatchesData: {
      type: Array,
      default: () => {}
    },
    companyTypeId: {
      type: Number,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isHauler () {
      const companyProfile = this.selectedCompanyProfile
      return (
        companyProfile && companyProfile.companyTypeId === this.companyTypeId
      )
    },
    dispatchCountMap () {
      const approvalCount = {}
      this.dispatchesData.forEach(dispatch => {
        if (approvalCount[dispatch.importApproval.code]) { // could change code to id instead
          approvalCount[dispatch.importApproval.code]++
        } else {
          approvalCount[dispatch.importApproval.code] = 1
        }
      })
      return approvalCount
    },
    headers () {
      const headers = []
      headers.push({ text: 'Dispatch Code', value: 'dispatchCode' })
      headers.push({ text: 'Start Date', value: 'startDate' })
      headers.push({ text: 'End Date', value: 'endDate' })
      headers.push({ text: 'Driver', value: 'driver' })
      headers.push({ text: 'Cell Phone', value: 'cellPhone' })
      headers.push({ text: 'Truck', value: 'truck' })
      headers.push({ text: 'License Plate', value: 'plateNo' })
      headers.push({ text: 'Truck Number', value: 'truckNumber' })
      headers.push({ text: 'Haul Request Code', value: 'haulRequestCode' })
      headers.push({ text: 'PickUp Site', value: 'pickUpSite' })
      headers.push({ text: 'DropOff Site', value: 'dropOffSite' })
      headers.push({ text: 'Approximate Loads', value: 'loads' })
      headers.push({ text: 'Status', value: 'status', align: 'center' })
      headers.push({ text: 'Actions', value: 'actions', align: 'end' })
      return headers
    }
  },
  methods: {
    chipColor (name) {
      return chipColorClass(name)
    },
    formatDate (date) {
      return moment(date).format('YYYY-MMM-DD hh:mm a')
    },
    formatPhone (phone) {
      return formatPhoneString(phone)
    },
    formatSiteAddress (site) {
      return `${site.name} (${site.siteCode}), ${site.address.addressLine1}, ${site.address.city.name}`
    },
    sendSMS () {
      //
    },
    editDispatch () {
      //
    },
    calculateApproximateLoads (item) {
      const totalVolume = item.importApproval.loadQuantity * item.importApproval.loadVolume
      const totalDispatchCount = this.dispatchCountMap[item.importApproval.code]
      const truckVolume = item.vehicle.model.maxVolume
      return Math.ceil(totalVolume / totalDispatchCount / truckVolume)
    }
  }
}
</script>
<style>
.container.unreactive-margins {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
}
</style>
