<template>
   <dispatches-template />
</template>
<script>

// Components
import DispatchesTemplate from '@/components/dispatches/DispatchesTemplate.vue'

export default {
  name: 'DispatchesView',
  components: {
    DispatchesTemplate
  }
}

</script>
