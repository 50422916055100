<template>
  <div>
    <dispatches-form
      :formIsOpen="formIsOpen"
      @close-form="closeForm"
      @submit-dispatch-form="submitDispatch"
    />

    <v-row class="mx-2 mt-1">
      <v-col cols="9">
        <v-tabs v-model="activeTab">
          <v-tab value="table">Table</v-tab>
          <v-tab value="cards">Cards</v-tab>
          <!--<v-tab value='map'>Map</v-tab>-->
        </v-tabs>
      </v-col>
      <v-col cols="3" class="text-right justify-end d-flex align-center">
        <v-btn
          v-if="haulerCompanyTypeId"
          elevation="0"
          outlined
          prepend
          variant="outlined"
          @click="openDispatchForm"
          ><v-icon>mdi-plus</v-icon>New Dispatch</v-btn
        >
      </v-col>
    </v-row>

    <v-window v-model="activeTab">
      <v-window-item>
        <dispatches-table
          :dispatchesData="dispatchesData"
          :companyTypeId="haulerCompanyTypeId"
          @viewSegment="(item) => viewSegment(item)"
          @viewInfo="(item) => viewInfo(item)"
        />
      </v-window-item>

      <v-window-item>
        <dispatches-card :dispatches-data="dispatchesData" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
// import { ref } from 'vue'
import DispatchesTable from '@/components/dispatches/DispatchesTable.vue'
import DispatchesForm from '@/components/dispatches/DispatchesForm.vue'
import DispatchesCard from '@/components/dispatches/DispatchesCard.vue'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { mapState, mapGetters } from 'vuex' // import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  components: {
    DispatchesTable,
    DispatchesCard,
    DispatchesForm
  },
  data: () => ({
    activeTab: 0,
    dispatchesData: [],
    formIsOpen: false,
    filters: [],
    editDispatchData: null,

    haulerCompanyTypeId: 2 // TODO: NEED TO KNOW WHAT TO SET FOR THIS
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isHauler () {
      const companyProfile = this.selectedCompanyProfile
      return (
        companyProfile &&
        companyProfile.companyTypeId === this.haulerCompanyTypeId
      )
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await this.$store.dispatch('action/refreshActionCount')
      this.loadDispatches()
    },
    async loadDispatches () {
      const search = createPaginationObject(null, null, this.filters)
      const res = await this.$api.dispatches.read(search)
      this.dispatchesData = res.dispatches.map((dispatch) => {
        return {
          ...dispatch
        }
      })
    },
    openDispatchForm () {
      this.formIsOpen = true
    },
    closeForm () {
      this.formIsOpen = false
    },
    submitDispatch () {
      // row.id = this.dispatchesData.length
      // this.dispatchesData.push(row)
      this.loadDispatches()
    }
  }
}
</script>
<style>
.v-tabs.theme--dark div.v-tabs-bar {
  background-color: #121212 !important;
}
</style>
