// function takes in a timeString of type DateTime - eg. 1900-01-01 16:00:00.0000000
export function convertDateTimeToHumanReadable (dateTimeString) {
  let humanReadableTime = ''
  const date = new Date(dateTimeString)

  const hours = date.getHours()
  const minutes = date.getMinutes()

  humanReadableTime = hours % 12 || 12 // Convert hours to 12-hour format
  humanReadableTime += ':' + (minutes < 10 ? '0' : '') + minutes // Adding minutes
  humanReadableTime += hours >= 12 ? 'pm' : 'am' // Adding AM/PM

  return humanReadableTime
}

export function combineDateTime (date, time) {
  if (date && time) {
    const dateTime = date + ' ' + time
    return dateTime
  }
}
